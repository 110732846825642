<template>
  <b-overlay
    :show="showOverlay"
    rounded="xl"
  >
    <b-card
      no-body
    >
      <b-card-header>
        <b-card-title>{{ $t('MENU_TRANSLATE') }}</b-card-title>
      </b-card-header>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="primary"
              @click="$router.push({name: 'newTraduction'})"
            >
              {{ $t('ADD_TRADUCTION') }}
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('SEARCH_PLACEHOLDER')"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        v-if="filteredTraductions() && filteredTraductions().total"
        ref="refTraductionList"
        :items="filteredTraductions().value"
        responsive
        :fields="columns"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >
        <template #head()="data">
          {{ $t(data.field.label) }}
        </template>

        <template #cell(action)>
          &nbsp;
        </template>
        <template #cell(language)="data">
          <b-avatar
            :src="locales[data.item.language.locale]"
          />
          {{ $t(data.item.language.name) }}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <label> {{ $t('PAGINATION_SHOWING') }}</label>
            <v-select
              v-model="pageLength"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <span class="text-muted">{{ $t('PAGINATION_SHOWING_ELEMENT') }} {{ $t('PAGINATION_TOTAL') }} {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="filteredTraductions().total"
              :per-page="pageLength"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>
<script>
/* eslint-disable global-require */
import {
  BOverlay, BCard, BCardHeader, BCardTitle, BButton, BRow, BCol, BFormInput, BTable, BPagination, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BOverlay,
    BCard,
    BCardHeader,
    BCardTitle,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BPagination,
    vSelect,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showOverlay: false,
      searchQuery: '',
      columns: [
        {
          label: '',
          key: 'action',
        },
        {
          label: 'TRADUCTION_CHAMPS',
          key: 'field',
        },
        {
          label: 'TRADUCTION_LANGUAGE',
          key: 'language',
        },
        {
          label: 'TRADUCTION_TRADUCTION',
          key: 'traduction',
        },
      ],
      pageLength: 10,
      perPageOptions: [5, 10, 25, 50, 100],
      currentPage: 1,
      locales: {},
    }
  },
  computed: {
    traductions() {
      return this.$store.getters['localeTraduction/getTraductions']
    },
    dataMeta() {
      const localItemsCount = this.filteredTraductions().total
      return {
        from: this.pageLength * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.pageLength * (this.currentPage - 1) + this.pageLength,
        of: localItemsCount,
      }
    },
  },
  watch: {
    searchQuery() {
      this.filteredTraductions()
    },
    pageLength() {
      this.currentPage = 1
      this.filteredTraductions()
    },
  },
  mounted() {
    this.showOverlay = true

    this.locales = {
      fr: require('@/assets/images/flags/france.png'),
      en: require('@/assets/images/flags/royaume-uni.png'),
    }

    this.$store.dispatch('localeTraduction/fetchTraductions', { no_format: true })
      .then(() => {
        this.filteredTraductions()
        this.showOverlay = false
      })
      .catch(() => {
        this.showOverlay = false
        this.$router.push('/pages/error-404')
      })
  },
  methods: {
    filteredTraductions() {
      const res = { value: [], total: 0 }

      let filtered = this.traductions

      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase()
        filtered = filtered.filter(el => el.field.toLowerCase().includes(search) || el.traduction.toLowerCase().includes(search))
      }

      res.total = filtered.length
      res.value = filtered.slice(this.pageLength * (this.currentPage - 1), this.pageLength * this.currentPage)

      return res
    },
  },
}
</script>
